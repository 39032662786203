import React, { useEffect, useMemo } from 'react'
import { observer } from 'mobx-react'
import { message as antMessage } from 'antd'
import { useStores } from '@/stores'
import {
  MessageInfoSvgStyled,
  MessageSuccessSvgStyled,
  MessageWarningSvgStyled,
  MessageDangerSvgStyled
} from './styled'

export const Message = observer((): React.ReactElement => {
  const { setMessage, message } = useStores()
  const icons = useMemo(
    () => ({
      info: <MessageInfoSvgStyled />,
      success: <MessageSuccessSvgStyled />,
      error: <MessageDangerSvgStyled />,
      warning: <MessageWarningSvgStyled />
    }),
    []
  )

  useEffect(() => {
    if (message) {
      if (message.key === 'create') {
        message.name = 'Заявка успешно отправлена'
      }
      if (message.key === 'fetchError') {
        message.name = 'Ошибка отправки сообщения. Попробуйте позже'
      }
      if (message.name !== null) {
        antMessage.config({ top: 0 })
        antMessage.open({
          type: message.type,
          icon: icons[message.type],
          content: message.name,
          className: 'ant-message-custom',
          duration: 4,
          onClick: () => antMessage.destroy(),
          style: {
            marginTop: '-.5rem',
            marginRight: '1rem',
            textAlign: 'right'
          }
        })
      }

      setMessage(null)
    }
  }, [icons, message, setMessage])

  return <></>
})
