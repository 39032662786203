import { createGlobalStyle } from 'styled-components'
import { html } from './html'
import { fonts } from './fonts'
import { antd } from './antd'

export const GlobalStyle = createGlobalStyle`
  ${html}
  ${fonts}
  ${antd}
`
