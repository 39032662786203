export const BREAKPOINTS = {
  small: '420px',
  medium: '768px',
  large: '1024px'
}

export const Z_INDEX = {
  overlay: 800,
  modal: 1010,
  loader: 1050
}

export const PALETTE = {
  white: '#FFFFFF',
  black: '#232323',
  primary: ['#00AAE6', '#3A84BA', '#2A6495', '#EAFBFE', '#1890FF'],
  text: ['#232323', '#687D92', '#8496A8'],
  red: ['#FF4D4F', '#FF7875', '#F5222D'],
  orange: ['#FF7A45', '#FFA940'],
  green: ['#52C41A'],
  yellow: ['#FADB14'],
  blue: '#0073FA',
  grey: [
    '#F3F3F6', // 0
    '#9BA7B8', // 1
    '#B2B9C7', // 2
    '#D8DDE3', // 3
    '#BFC8D0', // 4
    '#A7B2BD', // 5
    '#FAFAFA', // 6
    '#7B8C9E', // 7
    '#C8CCD6', // 8
    '#DEDFE6', // 9,
    '#53606C', // 10,
    '#F0F0F0' // 11
  ]
}

const fontsCommon = 'Source Sans Pro, Helvetica, sans-serif'
export const FONTS = {
  light: `'ALSHaussHairline', ${fontsCommon}`,
  lightItalic: `'ALSHaussHairlineItalic', ${fontsCommon}`,
  regular: `'ALSHaussRegular', ${fontsCommon}`,
  medium: `'ALSHaussMedium', ${fontsCommon}`,
  bold: `'ALSHaussBold', ${fontsCommon}`,
  semiBold: `'ALSHaussBlack', ${fontsCommon}`
}

export const theme = {
  token: {
    colorPrimary: PALETTE.primary[0],
    colorInfo: PALETTE.primary[0],
    colorSuccess: PALETTE.green[0],
    colorWarning: PALETTE.orange[0],
    colorError: PALETTE.red[0],
    borderRadiusXS: 4,
    borderRadiusSM: 8,
    borderRadius: 12,
    borderRadiusLG: 16,
    colorTextBase: PALETTE.text[0],
    colorTextSecondary: PALETTE.grey[2],
    colorBorder: PALETTE.grey[5],
    wireframe: false
  },
  components: {
    Form: {
      fontFamily: FONTS.regular,
      labelColor: PALETTE.text[1]
    },
    Button: {
      controlHeight: 36,
      controlHeightSM: 24,
      controlHeightLG: 40,
      fontSize: 16,
      fontSizeLG: 20,
      fontSizeSM: 14,
      borderRadius: 40,
      borderRadiusLG: 40,
      borderRadiusSM: 40,
      paddingInline: 14,
      paddingInlineLG: 16,
      paddingInlineSM: 8,
      borderColorDisabled: PALETTE.grey[5],
      defaultColor: PALETTE.primary[0],
      colorDefaultBorderHover: PALETTE.grey[9],
      colorDefaultActive: PALETTE.grey[8],
      colorDefaultHover: PALETTE.grey[9],
      defaultBorderColor: PALETTE.grey[0],
      defaultBg: PALETTE.grey[0],
      colorPrimaryHover: PALETTE.primary[1],
      colorPrimaryActive: PALETTE.primary[2],
      colorLinkHover: PALETTE.primary[1],
      colorLinkActive: PALETTE.primary[2],
      colorErrorHover: PALETTE.red[1],
      colorErrorBorderHover: PALETTE.red[0],
      fontFamily: FONTS.regular
    },
    Typography: {
      fontSizeHeading1: 38,
      fontFamilyCode: FONTS.regular,
      fontWeightStrong: 400
    },
    Input: {
      borderRadius: 8,
      borderRadiusSM: 8,
      borderRadiusLG: 8,
      fontSize: 14,
      fontSizeLG: 14,
      fontSizeSM: 14,
      controlHeight: 38,
      controlHeightLG: 38,
      controlHeightSM: 32,
      colorTextPlaceholder: PALETTE.grey[2]
    },
    InputNumber: {
      borderRadius: 8,
      borderRadiusSM: 8,
      borderRadiusLG: 8,
      fontSize: 14,
      fontSizeLG: 14,
      fontSizeSM: 14,
      controlHeight: 38,
      controlHeightLG: 38,
      controlHeightSM: 32
    },
    DatePicker: {
      borderRadius: 8,
      borderRadiusSM: 8,
      borderRadiusLG: 8,
      fontSize: 14,
      fontSizeLG: 14,
      fontSizeSM: 14,
      controlHeight: 38,
      controlHeightLG: 38,
      controlHeightSM: 32,
      colorTextPlaceholder: PALETTE.grey[2]
    },
    Select: {
      fontFamily: FONTS.regular,
      borderRadius: 8,
      borderRadiusSM: 8,
      borderRadiusLG: 8,
      fontSize: 14,
      fontSizeLG: 14,
      fontSizeSM: 14,
      controlHeight: 38,
      controlHeightLG: 38,
      controlHeightSM: 32,
      multipleItemBg: PALETTE.grey[3],
      multipleItemBorderColor: PALETTE.grey[4],
      colorTextPlaceholder: PALETTE.grey[2]
    },
    ColorPicker: {
      colorBorder: 'transapernt',
      activeBorderColor: 'transapernt',
      activeShadow: 'transparent',
      errorActiveShadow: 'transparent',
      warningActiveShadow: 'transparent',
      boxShadowPopoverArrow: 'transparent',
      boxShadowSecondary: 'transparent',
      fontSize: 14,
      fontSizeLG: 14,
      fontSizeSM: 14,
      controlHeight: 32,
      controlHeightLG: 32,
      controlHeightSM: 32
    },
    Tooltip: {
      colorBgSpotlight: '#000000BF',
      colorText: '#000000BF',
      boxShadowSecondary: 'transparent',
      controlHeight: 29,
      fontSize: 14,
      lineHeight: 1.2,
      paddingSM: 12,
      paddingXS: 8,
      fontFamily: FONTS.regular,
      borderRadius: 8
    },
    Tag: {
      defaultBg: PALETTE.grey[3],
      defaultColor: PALETTE.text[0],
      colorBorder: PALETTE.grey[4],
      borderRadiusSM: 4,
      fontFamily: FONTS.regular,
      marginXS: 4
    },
    Modal: {
      titleColor: PALETTE.text[0],
      colorIcon: PALETTE.grey[1],
      borderRadiusSM: 8,
      borderRadiusLG: 8
    },
    Checkbox: {
      borderRadiusSM: 4,
      colorPrimary: PALETTE.primary[0],
      colorPrimaryBorder: PALETTE.primary[0],
      colorPrimaryHover: PALETTE.primary[0],
      colorBorder: PALETTE.grey[1],
      colorBgContainerDisabled: PALETTE.grey[3],
      colorText: PALETTE.text[0],
      colorTextDisabled: PALETTE.text[1],
      fontFamily: FONTS.regular
    },
    Table: {
      rowHoverBg: PALETTE.white,
      borderRadius: 4,
      headerBg: PALETTE.white,
      headerFilterHoverBg: 'transparent',
      headerSortHoverBg: 'transparent',
      headerSortActiveBg: 'transparent'
    },
    Slider: {
      handleActiveColor: PALETTE.primary[2],
      trackBg: PALETTE.primary[0],
      trackHoverBg: PALETTE.primary[0],
      dotActiveBorderColor: PALETTE.primary[0],
      handleColor: PALETTE.primary[0],
      colorPrimaryBorderHover: PALETTE.primary[0],
      handleSizeHover: 14,
      handleSize: 14,
      controlSize: 8,
      handleLineWidthHover: 2,
      railSize: 4
    }
  }
}
