import { css } from 'styled-components'

import { FONTS, PALETTE } from '@/styles/constants'

export const antd = css`


/* btn */

.ant-btn {
  font-size: 1rem;
  font-family: ${FONTS.medium};

  &.ant-btn-lg {
    font-size: 1.25rem;
  }

  &.ant-btn-sm {
    display: flex;
    gap: .25rem;
    align-items: center;
    justify-content: center;
    height: 1.06rem;
    padding: 0 .4375rem;
    font-size: .875rem;
    font-family: ${FONTS.regular};
    line-height:  1.05rem;
    border-radius: 2.5rem;
  }
}

.ant-btn-default:not(:disabled, .ant-btn-disabled):hover {
  &&& {
    color: ${PALETTE.primary[0]};
    background: ${PALETTE.grey[9]};
    border-color: transparent;

    &.ant-btn-dangerous {
      color: ${PALETTE.red[1]};
    }
  }
}

.ant-btn-default:not(:disabled, .ant-btn-disabled):active {
  &&& {
    color: ${PALETTE.primary[0]};
    background: ${PALETTE.grey[8]};
    border-color: transparent;

    &.ant-btn-dangerous {
      color: ${PALETTE.red[1]};
    }
  }
}

.ant-btn-dangerous {
  &&& {
    &.ant-btn-default {
      border-color: transparent;
    }

    &.ant-btn-primary {
      border-color: ${PALETTE.red[0]};
    }

    &.ant-btn-dashed {
      background-color: ${PALETTE.white};
    }

    &:disabled {
      border-color: ${PALETTE.grey[5]};
    }
  }
}


.ant-btn.ant-btn-sm {
  font-size: .875rem;
  font-family: ${FONTS.regular};
}

.ant-popover {
  & .ant-popover-inner {
    padding: 0;
    border-radius: .5rem;
  }
 
  & .ant-popover-title {
    margin-bottom: 0;
  }
}



.ant-form-large .ant-form-item .ant-form-item-label>label {
  height:  auto;
}

.ant-form-item .ant-form-item-label > label {
  font-weight: 400;
  font-size: .875rem;
  font-family:  ${FONTS.regular};
  line-height: 1.05rem;
}

.ant-form-item-label {
  font-weight: 400;
  font-size: .875rem;
  font-family: ${FONTS.regular};
  font-style: normal;
  line-height: 1.05rem;
}

.ant-form-item .ant-form-item-label {
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
}

.ant-form-item .ant-form-item-label {
  .ant-form-item-required::after {
    color: ${PALETTE.red[0]};
    visibility: visible;
    content: '*';
  }

  .ant-form-item-required > .ant-form-item-tooltip {
    position: absolute;
    right: -10px;
    color: red;
  }
}



.ant-color-picker-trigger {
  display: flex;
  min-width: 32px;
  max-width: 32px;
  padding: 0;
  border-radius: .375rem;

  &:hover {
    border-color: transparent;
  }

  .ant-color-picker-trigger {
    display: flex;
    min-width: 32px;
    max-width: 32px;
    padding: 0;
    border-radius: .375rem;

    .ant-color-picker-color-block-inner {
      border-color: ${PALETTE.grey[2]};
    }
  }
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
  width: 24px;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel table {
  width: 280px;
}

.ant-picker-date-panel {
  max-width: 300px;
}

.ant-picker-dropdown .ant-picker-time-panel {
  width: 300px;
}

.ant-picker {
  width: 100%;
}

.ant-picker-large,
.ant-picker {
  height: 38px;
}

.ant-picker-small {
  height: 32px;
}

.ant-input-wrapper {
  &&& {
    .ant-form-item {
      margin-bottom: 0;

      .ant-select {
        min-width: 10rem;

        .ant-select-selection-item {
          text-align: end;
        }
      }
    }
  }
}

/* input number hide arrows */
.ant-input-number-handler-wrap {
  visibility: hidden;
}

.ant-input {
  &:hover,
  &:focus {
    border-color: ${PALETTE.primary[0]};
  }
}

.ant-input-number {
  width: 100%;
}

input:focus-visible {
  outline: none ;
}

.ant-btn:not(:disabled):focus-visible,
.ant-input:focus-visible,
.ant-input-number:focus-visible,
.ant-radio-wrapper .ant-radio-input:focus-visible+.ant-radio-inner ,
.ant-checkbox .ant-checkbox-input:focus-visible+.ant-checkbox-inner {
  border-color: ${PALETTE.primary[0]};
  outline: none ;
  outline-offset: 0;
  transition:
    outline-offset 0s,
    outline 0s;
}

.ant-form {
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}

/* message */
.ant-message {
  margin-top: 1.5rem;
  
  &&& {
    .ant-message-custom {
      .ant-message-notice-content {
        padding: .625rem 1rem;
        cursor: pointer;
      
        .ant-message-custom-content {  
          display: flex;
          gap: .5rem;
          align-items: center;
          color: ${PALETTE.black};
          font-weight: 400;
          font-size: .875rem;
          line-height: 1.05rem;
        }
      }
    }
  }
}

/* tags */
.ant-tag {
  padding: .25rem .25rem .25rem .5rem;
  font-weight: 400;
  font-size: 1rem;
  margin-inline-end: .25rem;

  & .ant-tag-close-icon {
    margin-inline-start: .25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    & > svg {
      width: .625rem;
      height: .625rem;

      & > g {
        fill: ${PALETTE.text[1]};
      }
    }
  }
}

/* button */
.ant-btn.ant-btn-text:not(:disabled, .ant-btn-disabled):hover {
  background: transparent;
}

.ant-btn-link {
  padding-right: 0;
  padding-left: 0;
}

/* select */
.ant-select {
  .ant-select-arrow {
    & > svg {
      width: 1rem;
      height: 1rem;
    }

    transition: all .3s;
  }

  &.ant-select-open > .ant-select-arrow {
    transform: rotate(-180deg);
  }

  .ant-select-multiple {
    .ant-select-selection-item {
      background: ${PALETTE.grey[2]};
      border: 1px solid ${PALETTE.grey[5]};
      border-radius: .25rem;

      .ant-select-selection-item-remove svg {
        width: .625rem;
        height: .625rem;
      }
    }
  }
}

.ant-input-group-wrapper-outlined {
  .ant-input-group-addon {
    color: ${PALETTE.grey[2]};
    background: ${PALETTE.white};
    border-left: none;
    
    .ant-select-selection-item {
      color: ${PALETTE.grey[2]};
    }
  }

  input {
    border-right: none;
    
    &:hover {
      border-color: ${PALETTE.primary[0]};
      border-right: none;
      box-shadow: none;
    }

    &:hover,&:active,&:focus,&:focus-within {
      border-color: ${PALETTE.primary[0]};
      border-right: none;
      box-shadow: none;
    }


    &:hover + .ant-input-group-addon,
    &:focus + .ant-input-group-addon,
    &:focus-visible + .ant-input-group-addon,
    &:focus-within + .ant-input-group-addon {
      border-color: ${PALETTE.primary[0]};
      border-left: none;
    }
  }

  .ant-select-outlined {
    background-color: transparent;
  }
}

.ant-select-dropdown {
  padding: .25rem 0;
  border: .067rem solid transparent;
  border-radius: .625rem;
  box-shadow:
    0 9px 28px 8px rgb(0 0 0 / 5%),
    0 6px 16px 0 rgb(0 0 0 / 8%),
    0 3px 6px -4px rgb(0 0 0 / 12%);

  .ant-select-item-empty {
    display: flex;
    align-items: center;
    padding: .75rem;
    color: ${PALETTE.black};
    font-weight: 400;
    font-size: .875rem;
    font-family: ${FONTS.regular};
    font-style: normal;
    line-height: 1.05rem;
    border-radius: 0;
    cursor: pointer;

    .ant-empty {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .ant-select-item-group {
    font-size: .875rem;
    line-height: 1.2rem;
  }

  .ant-select-item-option {
    padding: .75rem;
    color: ${PALETTE.black};
    font-weight: 400;
    font-size: 1rem;
    font-family: ${FONTS.regular};
    font-style: normal;
    line-height: 1.2rem;
    border-radius: 0;

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      text-wrap: pretty;
    }
    

    &.ant-select-item-option-selected {
      background-color: ${PALETTE.grey[0]};
    }

    &:hover {
      background-color: ${PALETTE.grey[0]};
    }
  }

  .rc-virtual-list-scrollbar-thumb {
    width: .375rem !important;
    background: ${PALETTE.grey[2]} !important;
  }
}

/* picker */
.ant-picker-dropdown {
  .ant-picker-header {
    padding: .56rem 0;
  }

  .ant-picker-body {
    .ant-picker-content {
      .ant-picker-cell-inner {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .ant-picker-footer {
    padding: .75rem .5rem;
  }
}

.ant-table {
  .ant-table-thead > tr > th {
    background: ${PALETTE.white};
  }
}

.ant-menu-submenu-popup {
  &&& {
    padding-left: 1.3rem;
  }

  & .ant-menu.ant-menu-sub.ant-menu-vertical {
    min-width: 11rem;
    max-width: 11rem;
    max-height: 19rem;
    margin-top: -1rem;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
    border-radius: .5rem;
    
    .ant-menu-title-content {
      transition: all 0s;

      &:focus-visible {
        outline: none;
      }
    }

    & .ant-menu-item {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 2.5rem;
      margin: 0;
      padding: .56rem 1rem;
      background-color: transparent;
      transition: all 0s;

      &.ant-menu-item-selected {
        color: inherit;
        transition: all 0s;
      }

      &:hover {
        background: ${PALETTE.grey[6]};
        border-radius: 0;
        transition: all 0s;
      }

      
      &.menu-child-last {
        position: sticky;
        bottom: 0;
        justify-content: center;
        background: white;
        border-top: 1px solid ${PALETTE.grey[0]};
        border-radius: 0;
        
        &:hover {
          background: ${PALETTE.grey[6]};
          border-radius: 0;
          transition: all 0s;
        }
      }

      &.menu-large-child {
        min-height: 4rem;
      }
    }
  }
}

/* dropdown */

.ant-dropdown {
  background-color: transparent;
  border-radius: .5rem;
  box-shadow: 0 1px 2px #38414A26;

  &.dropdown {
    inset: 68px auto auto 180px !important;
  }

  .ant-dropdown-menu {
    min-width: 10rem;
    padding: 0;
    overflow: hidden;
    border-radius: .5rem;

    .ant-dropdown-menu-item {
      padding: .75rem 1rem;
      border-radius: 0;

      &:hover {
        color:${PALETTE.primary[0]};
        background-color: ${PALETTE.white};
      }

      .ant-dropdown-menu-title-content {
        display: flex;
        align-items: center;
        font-size: .875rem;
        font-family: ${FONTS.regular};
      }
    }
  }

  .ant-table-filter-dropdown-btns {
    padding: .5rem .63rem !important;

    .ant-btn {
      height: auto !important;
      font-size: .875rem;
      font-family: ${FONTS.regular};
      line-height: 1.05rem;
    }
  }
}

/* notification  */
.ant-notification .ant-notification-notice {
  border-radius: .25rem;

  .ant-notification-notice-with-icon {
    .ant-notification-notice-message,
    .ant-notification-notice-description {
      font-family: ${FONTS.medium};
    }
  }
}

/* modal */
.ant-modal-root {
  .ant-modal-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-modal {
      top: 0;
      box-sizing: border-box;
      width: 25rem !important;
      padding: 0;
      background: ${PALETTE.white};
      border-radius: .5rem;
      box-shadow:
        0 9px 28px 8px rgb(0 0 0 / 5%),
        0 6px 16px 0 rgb(0 0 0 / 8%),
        0 3px 6px -4px rgb(0 0 0 / 12%);

      &.modal-large {
        width: 60vw !important;
        height: 80vh !important;

        .ant-modal-content {
          width: 100%;
          height: 100%;
          overflow: hidden;
        }
      }

      /* stylelint-disable-next-line no-descending-specificity */
      .ant-modal-content {
        display: flex;
        flex-direction: column;
        padding: 1rem;
        row-gap: 1.5rem;

        .ant-modal-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0;

          .ant-modal-title {
            font-weight: 500;
            font-size: 1rem;
            font-family: ${FONTS.medium};
            line-height: 1.4rem;
          }
        }

        .ant-modal-close {
          top: 1rem;
          right: 1rem;
          width: 1rem;
          height: 1rem;

          svg {
            width: 1rem;
            height: 1rem;

            g {
              fill: ${PALETTE.grey[2]};
            }
          }

          &:hover {
            background-color: transparent;
          }
        }

        .ant-modal-body {
          box-sizing: border-box;
          width: 100%;
          font-size: 1rem;
          font-family: ${FONTS.regular};
          line-height: 140%;
          white-space: pre-line;
        }

        .ant-modal-footer {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          box-sizing: border-box;
          width: 100%;
          margin: 0;
        }
      }
    }
  }
}

.ant-drawer {
  &:focus-visible {
    outline: none;
  }

  .ant-drawer-content-wrapper {
    box-shadow: none;

    &::before {
      position: absolute;
      z-index: -1;
      width: 1px;
      height: calc(100% - .5rem);
      margin-top: .25rem;
      box-shadow: 0 4px 4px 0 #00000040;
      content: '';
    }
  }
}

.ant-tooltip {
  max-width: 25rem;
  border-radius: .5rem;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);;

  .ant-tooltip-arrow {
    &&& {
      &::after,
      &::before {
        background: #000000BF;
      }
    }
  }

  .ant-tooltip-content {
    border-radius: .5rem;

    &&& {
      .ant-tooltip-inner {
        font-size: .875rem;
        font-family: ${FONTS.regular};
        line-height: 1.4rem;
        background-color: #000000BF;
      }
    }
  }
}

.ant-divider {
  color: #0000000F;

  &.ant-divider-horizontal {
    height: .0625rem;
    margin: .5rem 0;
  }

  &.ant-divider-vertical {
    margin: 0 .5rem;
  }
}

.ant-upload-wrapper {
  &.ant-upload-picture-card-wrapper {
    .ant-upload-list.ant-upload-list-picture-card {
      .ant-upload-list-item-container {
        .ant-upload-list-item.ant-upload-list-item-done {
          padding: .56rem;
          border-radius: .5rem;

          .ant-upload-list-item-actions {
            display: flex;
            justify-content: center;
          }
        }
      }

      .ant-upload {
        &.ant-upload-select {
          background-color: #FAFAFA;

          .ant-upload {
            color: ${PALETTE.text[1]};
            font-size: .875rem;
            font-family: ${FONTS.regular};

            svg {
              margin-bottom: 0;

              path {
                stroke: ${PALETTE.text[1]};
              }
            }
          }

          &:hover {
            border-color: ${PALETTE.primary[0]};
          }
        }
      }
    }
  }
}
`
