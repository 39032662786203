import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import ru from 'antd/locale/ru_RU'
import en from 'antd/locale/en_US'
import { type Locale } from 'antd/lib/locale'
import { resources } from '@/i18n/resources'
import('dayjs/locale/en')
import('dayjs/locale/ru')

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ru',
    fallbackLng: 'ru',
    react: {
      useSuspense: true
    }
  })

export const locale: Record<string, Locale> = {
  ru,
  en
}

export default i18n
