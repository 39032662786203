import styled from 'styled-components'
import { FONTS, PALETTE } from '@/styles/constants'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: ${PALETTE.white};
  border-radius: .75rem;
  outline: 0;
`

export const Title = styled.h4`
  margin: 0;
  color: ${PALETTE.black};
  font-size: 2.375rem;
  font-family: ${FONTS.medium};
  line-height: 2.85rem;
`

export const Desc = styled.p`
  margin: 0;
  color: ${PALETTE.text[1]};
  font-size: 1rem;
  font-family: ${FONTS.regular};
  line-height: 1.4rem;
`
