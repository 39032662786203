import type { SRequest } from '@/services/request'
import type { TRequest, TResponseData } from '@/types/http'

export interface IRequest {
  path: string
  request: SRequest
  middleware: (data: unknown, method: string | null) => void
  loading?: boolean
}

export class Request implements IRequest {
  loading: boolean
  path: string
  request: SRequest
  middleware: (data: unknown, method: string | null) => void

  constructor(store: unknown) {
    Object.assign(this, store)
  }

  setResponse = (resp: unknown, method: string | null = null): void => {
    this.middleware(resp, method)
  }

  get = async (id: string, modeModel = false): Promise<TResponseData> => {
    this.loading = true
    const queryParam = modeModel ? `?model=true` : ''
    const resp = await this.request.get(`${this.path}/${id}${queryParam}`)
    this.setResponse(resp, 'read')
    this.loading = false
    return resp
  }

  update = async (source: TRequest): Promise<TResponseData> => {
    this.loading = true
    const resp = await this.request.patch(this.path, source)
    this.setResponse(resp, 'update')
    this.loading = false
    return resp
  }
}

