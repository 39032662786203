import Cookies from 'js-cookie'

export const removeCookies = (key: string): void => {
  Cookies.remove(key)
}

export const getCookies = (key: string): string | null => {
  const cookie = Cookies.get(key) ?? null

  if (!cookie || (cookie && cookie.length === 0)) {
    removeCookies(key)
    return null
  }
  return cookie
}

export const setCookies = (obj: TCookies): void => {
  const keys = Object.keys(obj) as Array<keyof TCookies>
  keys.forEach((key: keyof TCookies) => {
    if (!obj[key]) {
      removeCookies(key)
      return
    }
    Cookies.set(key, obj[key] ?? '')
  })
}
