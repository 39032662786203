import styled from 'styled-components'
import { PALETTE } from '@/styles/constants'
import { MessageDangerSvg, MessageInfoSvg, MessageSuccessSvg } from '@/assets/images'

export const MessageInfoSvgStyled = styled(MessageInfoSvg)`
  width: 1rem;
  height: 1rem;

  path {
    fill: ${PALETTE.primary[0]};
  }
`
export const MessageSuccessSvgStyled = styled(MessageSuccessSvg)`
  width: 1rem;
  height: 1rem;

  path {
    fill: ${PALETTE.green[0]};
  }
`
export const MessageWarningSvgStyled = styled(MessageDangerSvg)`
  width: 1rem;
  height: 1rem;

  path {
    fill: ${PALETTE.orange[1]};
  }
`
export const MessageDangerSvgStyled = styled(MessageDangerSvg)`
  width: 1rem;
  height: 1rem;

  path {
    fill: ${PALETTE.red[2]};
  }
`