import { makeAutoObservable } from 'mobx'
import { SRequest } from '@/services/request'
import { Request } from '@/stores/module/Request/store'

export class RootStore {
  message: TMessage | null = null
  loading: boolean = false
  request: Request
  requestServices: SRequest

  constructor() {
    makeAutoObservable(this)
    this.requestServices = new SRequest(this)
    this.request = new Request(this)
  }

  setLoading = (bool: boolean): void => {
    this.loading = bool
  }

  setMessage = (message: TMessage | null): void => {
    this.message = message
  }
}
