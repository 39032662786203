import React from 'react'
import { ErrorPage } from './components/ErrorPage'

interface IErrorBoundary {
  children: React.ReactNode
}

type TState = {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<IErrorBoundary, TState> {
  constructor(props: IErrorBoundary) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): TState {
    return { hasError: true }
  }

  componentDidCatch(error: Error): void {
    this.setState({ hasError: true })
    console.warn('Warning:', error)
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <ErrorPage />
    }

    return this.props.children
  }
}
