import { makeAutoObservable } from 'mobx'
import type { RootStore } from '@/stores/rootStore'
import type { TResponseData } from '@/types/http'
import { Service } from './service'

export class Request {
  data: TResponseData

  service: Service

  constructor(root: RootStore) {
    makeAutoObservable(this)
    this.service = new Service({
      path: '/request',
      request: root.requestServices,
      middleware: this.setMiddleware
    })
    Object.assign(this)
  }

  setMiddleware = (data: TResponseData, method: string): void => {
    try {
      if (data) {
        console.log(data)
        this.data = data
      }
    } catch (e) {
      console.log('Error store:', e)
    }
  }
}
