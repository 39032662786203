import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios'
import { getCookies } from '@/utils/cookies'
import { AGRO_NAME } from '@/utils/env'
import type { RootStore } from '@/stores/rootStore'
import type { TRequest, TResponseData, TResponse } from '@/types/http'

const APP_NAME = process.env.APP_NAME ?? ''

type TRequestConfig = {
  token?: string | null
  language?: string | null
}

export class SRequest {
  instance: AxiosInstance

  constructor(rootStore?: RootStore) {
    const language = getCookies('cr_lng') ?? 'ru'
    const token = getCookies('cr_token') ?? null

    if (rootStore?.setMessage) {
      this.setMessage = rootStore?.setMessage
    }

    this.setInstance({ token, language })
  }

  getUrl = (url: string): string => `/api/${AGRO_NAME}${url}`

  getError = (error: unknown): TResponse => ({
    data: {
      message: {
        type: 'error',
        service: APP_NAME,
        key: 'fetchError',
        name: 'Fetch error',
        desc: JSON.stringify(error)
      }
    }
  })

  setMessage = (message: TMessage | null): void => {}

  setInstance = (opt: TRequestConfig): void => {
    this.instance = axios.create({
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json',
        ...(opt.language ? { 'Accept-Language': opt.language } : {}),
        ...(opt.token ? { Authorization: `Bearer ${opt.token}` } : {})
      }
    })
  }

  middleware = (response: TResponse | unknown): TResponseData => {
    const { data } = response as TResponse

    if (data.token && data.user?.language) {
      this.setInstance({ token: data.token, language: data.user.language })
    }
    if (data.message) {
      this.setMessage(data.message)
    }

    return data
  }

  get = (url: string, config?: AxiosRequestConfig): Promise<TResponseData> =>
    this.instance
      .get(this.getUrl(url), config)
      .then(this.middleware)
      .catch(e => this.middleware(this.getError(e)))

  post = async (
    url: string,
    body: TRequest,
    config?: AxiosRequestConfig
  ): Promise<TResponseData> => {
    try {
      const res = await this.instance.post(this.getUrl(url), body, config)
      return this.middleware(res)
    } catch (e) {
      return this.middleware(this.getError(e))
    }
  }

  patch = (
    url: string,
    body: TRequest,
    config?: AxiosRequestConfig
  ): Promise<TResponseData> =>
    this.instance
      .patch(this.getUrl(url), body, config)
      .then(this.middleware)
      .catch(e => this.middleware(this.getError(e)))

  put = (
    url: string,
    body: TRequest,
    config?: AxiosRequestConfig
  ): Promise<TResponseData> =>
    this.instance
      .put(this.getUrl(url), body, config)
      .then(this.middleware)
      .catch(e => this.middleware(this.getError(e)))

  delete = (url: string, config?: AxiosRequestConfig): Promise<TResponseData> =>
    this.instance
      .delete(this.getUrl(url), config)
      .then(this.middleware)
      .catch(e => this.middleware(this.getError(e)))
}
