import React, { type FC, lazy, Suspense } from 'react'
import { observer } from 'mobx-react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Loader } from '@/components/block/Loader'
import { APP_NAME } from './utils/env'

const Main = lazy(() => import('./pages/Main'))

React.useLayoutEffect = React.useEffect

export const Router: FC = observer(() => {
  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <Routes>
          <Route
            path={`/${APP_NAME}/*`}
            element={<Main />}
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
})
