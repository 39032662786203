import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { Wrapper, Title, Desc } from './styled'

export const ErrorPage: React.FC = () => {
  const [t] = useTranslation()

  const redirect: () => void = () => {
    window.location.href = window.location.href.replace(/\/agro.{0,}/i, '/agro')
  }

  return (
    <Wrapper>
      <Title>{t('errorPage.title')}</Title>
      <Desc>{t('errorPage.desc')}</Desc>
      <Button
        type="primary"
        onClick={redirect}
      >
        {t('errorPage.back')}
      </Button>
    </Wrapper>
  )
}
