import React, { StrictMode } from 'react'
import { observer } from 'mobx-react'
import { createRoot } from 'react-dom/client'
import { ConfigProvider } from 'antd'
import { ErrorBoundary } from '@/components/block/ErrorBoundary'
import { Router } from '@/router'
import { RootStoreProvider } from '@/stores'
import { GlobalStyle } from '@/styles/base'
import { theme } from '@/styles/constants'
import { locale } from './i18n'
import { Message } from './components/block/Message'

const container = document.getElementById('root')
const root = container && createRoot(container)

const App = observer(() => {
  return (
    <ErrorBoundary>
      <StrictMode>
        <ConfigProvider
          locale={locale.ru}
          theme={theme}
        >
          <RootStoreProvider>
            <Message />
            <GlobalStyle />
            <Router />
          </RootStoreProvider>
        </ConfigProvider>
      </StrictMode>
    </ErrorBoundary>
  )
})

root?.render(<App />)
