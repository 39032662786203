import { css } from 'styled-components'
import { FONTS, PALETTE } from '@/styles/constants'

export const html = css`
  html {
    overflow-x: hidden;
    font-size: 1rem;
    font-family: ${FONTS.regular};

    body {
      height: 100%;
      margin: 0;
      color: ${PALETTE.text};
      background-color: ${PALETTE.white};
    }
  }

  /* browser Chrome */
  input:-internal-autofill-selected,
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: #ffffff !important;
    box-shadow: 0 0 0 30px white inset !important;
  }

  /* scroll */
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }

  ::-webkit-scrollbar-thumb {
    background: ${PALETTE.grey[3]};
    border: 0 none ${PALETTE.grey[3]};
    border-radius: 50px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border: 0 none transparent;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${PALETTE.grey[1]};
    cursor: pointer;
  }

  ::-webkit-scrollbar-track:hover {
    background: #bdbdbd;
    cursor: pointer;
  }
`
