export const NODE_ENV = process.env.NODE_ENV ?? 'development'

export const APP_NAME = process.env.APP_NAME ?? 'template'
export const APP_SUB_NAME = process.env.APP_SUB_NAME ?? '_sub'
export const APP_PORT = process.env.APP_PORT ?? '8080'
export const APP_HOST = process.env.APP_HOST ?? 'template.com'
export const APP_LANGUAGE = process.env.APP_LANGUAGE ?? 'ru'

// DEV LOCAL ENV
export const LOCAL_IP = process.env.LOCAL_IP ?? ''
export const AGRO_NAME = process.env.AGRO_NAME ?? 'agro'
export const AGRO_PORT = Number(process.env.AGRO_PORT ?? '8103')
export const EMAIL_INFO = process.env.EMAIL_INFO ?? 'info@pickterra'
export const PHONE_INGO = process.env.PHONE_INGO ?? '88000000000'
export const TELEGRAM_SUPPORT = process.env.TELEGRAM_SUPPORT ?? 'pickterra'

// CALCULATED
export const IS_DEV = NODE_ENV === 'development'
export const URL_STATIC = `${IS_DEV ? '' : APP_HOST}/static`

export const getUrlService = (app_name: string = APP_NAME): string => {
  const port = process.env[`${app_name.toUpperCase()}_PORT`] ?? '8080'
  return `${IS_DEV ? `http://${LOCAL_IP}:${port}` : APP_HOST}/${app_name}`
}
