import React, { memo } from 'react'
import {
  Wrapper,
  Background,
  Container,
  Content,
  BounceFirst,
  BounceSecond
} from './styled'

export interface ILoader {
  color?: string
  size?: number
  className?: string
}

export const Loader: React.FC<ILoader> = memo(
  ({ color, size, className = 'loader' }) => (
    <Wrapper className={className}>
      <Background className={`${className}-background`} />
      <Container className={`${className}-container`}>
        <Content
          size={size}
          className={`${className}-content`}
        >
          <BounceSecond />
          <BounceFirst color={color} />
        </Content>
      </Container>
    </Wrapper>
  )
)
