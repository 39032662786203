import { Request } from '@/services/interface/Request'
import type { TRequest, TResponseData } from '@/types/http'

export class Service extends Request {
  send = async (source: TRequest): Promise<TResponseData> => {
    this.loading = true
    const resp = await this.request.put(this.path, { request: source })
    this.setResponse(resp)
    this.loading = false
    return resp
  }
}
