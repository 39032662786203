import React, { createContext, type ReactNode, useContext } from 'react'
import { observer } from 'mobx-react'
import { RootStore } from '@/stores/rootStore'

interface IStore {
  children: ReactNode
}

const rootStore = new RootStore()

const RootStoreContext = createContext(rootStore)

export const useStores = (): RootStore => useContext(RootStoreContext)

export const RootStoreProvider = observer(({ children }: IStore) => (
  <RootStoreContext.Provider value={rootStore}>
    {children}
  </RootStoreContext.Provider>
))
