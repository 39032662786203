import { css } from 'styled-components'
import ALSHaussBlackItalic from '@/assets/fonts/als_hauss/als_hauss_black_italic.woff'
import ALSHaussBlack from '@/assets/fonts/als_hauss/als_hauss_black.woff'
import ALSHaussBoldItalic from '@/assets/fonts/als_hauss/als_hauss_bold_italic.woff'
import ALSHaussBold from '@/assets/fonts/als_hauss/als_hauss_bold.woff'
import ALSHaussBookItalic from '@/assets/fonts/als_hauss/als_hauss_book_italic.woff'
import ALSHaussBook from '@/assets/fonts/als_hauss/als_hauss_book.woff'
import ALSHaussHairlineItalic from '@/assets/fonts/als_hauss/als_hauss_hairline_italic.woff'
import ALSHaussHairline from '@/assets/fonts/als_hauss/als_hauss_hairline.woff'
import ALSHaussLightItalic from '@/assets/fonts/als_hauss/als_hauss_light_italic.woff'
import ALSHaussLight from '@/assets/fonts/als_hauss/als_hauss_light.woff'
import ALSHaussMediumItalic from '@/assets/fonts/als_hauss/als_hauss_medium_italic.woff'
import ALSHaussMedium from '@/assets/fonts/als_hauss/als_hauss_medium.woff'
import ALSHaussRegularItalic from '@/assets/fonts/als_hauss/als_hauss_regular_italic.woff'
import ALSHaussRegular from '@/assets/fonts/als_hauss/als_hauss_regular.woff'
import ALSHaussThinItalic from '@/assets/fonts/als_hauss/als_hauss_thin_italic.woff'
import ALSHaussThin from '@/assets/fonts/als_hauss/als_hauss_thin.woff'

export const fonts = css`
  @font-face {
    font-weight: 100;
    font-family: ALSHaussHairline;
    font-style: normal;
    src:
      local('ALSHaussHairline'),
      url(${ALSHaussHairline}) format('woff');
  }

  @font-face {
    font-weight: 100;
    font-family: ALSHaussHairlineItalic;
    font-style: normal;
    src:
      local('ALSHaussHairlineItalic'),
      url(${ALSHaussHairlineItalic}) format('woff');
  }

  @font-face {
    font-weight: 200;
    font-family: ALSHaussThin;
    font-style: normal;
    src:
      local('ALSHaussThin'),
      url(${ALSHaussThin}) format('woff');
  }

  @font-face {
    font-weight: 200;
    font-family: ALSHaussThinItalic;
    font-style: normal;
    src:
      local('ALSHaussThinItalic'),
      url(${ALSHaussThinItalic}) format('woff');
  }

  @font-face {
    font-weight: 300;
    font-family: ALSHaussLight;
    font-style: normal;
    src:
      local('ALSHaussLight'),
      url(${ALSHaussLight}) format('woff');
  }

  @font-face {
    font-weight: 300;
    font-family: ALSHaussLightItalic;
    font-style: normal;
    src:
      local('ALSHaussLightItalic'),
      url(${ALSHaussLightItalic}) format('woff');
  }

  @font-face {
    font-weight: 400;
    font-family: ALSHaussBook;
    font-style: normal;
    src:
      local('ALSHaussBook'),
      url(${ALSHaussBook}) format('woff');
  }

  @font-face {
    font-weight: 400;
    font-family: ALSHaussBookItalic;
    font-style: normal;
    src:
      local('ALSHaussBookItalic'),
      url(${ALSHaussBookItalic}) format('woff');
  }

  @font-face {
    font-weight: 500;
    font-family: ALSHaussRegular;
    font-style: normal;
    src:
      local('ALSHaussRegular'),
      url(${ALSHaussRegular}) format('woff');
  }

  @font-face {
    font-weight: 500;
    font-family: ALSHaussRegularItalic;
    font-style: normal;
    src:
      local('ALSHaussRegularItalic'),
      url(${ALSHaussRegularItalic}) format('woff');
  }

  @font-face {
    font-weight: 600;
    font-family: ALSHaussMedium;
    font-style: normal;
    src:
      local('ALSHaussMedium'),
      url(${ALSHaussMedium}) format('woff');
  }

  @font-face {
    font-weight: 600;
    font-family: ALSHaussMediumItalic;
    font-style: normal;
    src:
      local('ALSHaussMediumItalic'),
      url(${ALSHaussMediumItalic}) format('woff');
  }

  @font-face {
    font-weight: 700;
    font-family: ALSHaussBold;
    font-style: normal;
    src:
      local('ALSHaussBold'),
      url(${ALSHaussBold}) format('woff');
  }

  @font-face {
    font-weight: 700;
    font-family: ALSHaussBoldItalic;
    font-style: normal;
    src:
      local('ALSHaussBoldItalic'),
      url(${ALSHaussBoldItalic}) format('woff');
  }

  @font-face {
    font-weight: 800;
    font-family: ALSHaussBlack;
    font-style: normal;
    src:
      local('ALSHaussBlack'),
      url(${ALSHaussBlack}) format('woff');
  }

  @font-face {
    font-weight: 800;
    font-family: ALSHaussBlackItalic;
    font-style: normal;
    src:
      local('ALSHaussBlackItalic'),
      url(${ALSHaussBlackItalic}) format('woff');
  }
`
